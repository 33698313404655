<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <profilesidebar></profilesidebar>
            </div>
          </div>
          <!-- / Profile Sidebar -->

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-sm-6">
                    <h3 class="card-title">Dependent</h3>
                  </div>
                  <div class="col-sm-6">
                    <div class="text-end">
                      <a
                        href="#modal_form"
                        data-bs-toggle="modal"
                        class="btn btn-primary btn-sm"
                        tabindex="0"
                      >Add Dependent</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <!-- Dependent Tab -->
                <div class="card card-table mb-0">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Dependent</th>
                            <!-- <th>Relationship</th> -->
                            <th>gender</th>
                            <th>Date of Birth</th>
                            <!-- <th>Blood Group</th> -->
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="col-md-6 col-lg-4 col-xl-3"
                            v-for="item in dependent"
                            :key="item.patientid"
                          >
                            <td>
                              <h2 class="table-avatar">
                                <span class="avatar avatar-sm me-2">
                                  <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
                                </span>
                                {{ `${item.firstname} ${item.lastname}` }}
                              </h2>
                            </td>
                            <!-- <td>{{ item.Relationship }}</td> -->
                            <td>{{ transformGender(item.gender) }}</td>
                            <td>{{ formatDate(item.dateof_birth) }}</td>
                            <!-- <td>{{ item.BloodGroup }}</td> -->
                            <td>
                              <div class="table-action">
                                <a
                                  v-if="item.listyn"
                                  href="#edit_form"
                                  class="btn btn-sm bg-info-light me-1"
                                  data-bs-toggle="modal"
                                  @click="editDependent(item)"
                                >
                                  <i class="fas fa-edit"></i> Edit
                                </a>
                                <a
                                  v-if="item.listyn"
                                  href="javascript:void(0);"
                                  class="btn btn-sm bg-danger-light"
                                  @click="deactivateDependent(item)"
                                >
                                  <i class="fas fa-times"></i> Deactived
                                </a>
                                <a
                                  v-else
                                  href="javascript:void(0);"
                                  class="btn btn-sm bg-success-light"
                                  @click="activateDependent(item)"
                                >
                                  <i class="fas fa-check"></i> Activate
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- /Dependent Tab -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <indexfooter></indexfooter>
  </div>
  <actions :dependent="dependent" :selectedDependent="selectedDependent" :fetchDependents="fetchDependents"></actions>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
// import dependent from "@/assets/json/patients/dependent.json";
export default {
  data() {
    return {
      title: "Dashboard",
      text: "Home",
      text1: "Dashboard",
      dependent: [],
      selectedDependent: []
    };
  },

  methods: {
    formatDate(date) {
      if (date == null) {
        return null;
      } else {
        return new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
        });
      }
    },
    async fetchDependents() {
      const patientId = sessionStorage.getItem("userdbid");
      try {
        console.log("Patient ID:", this.patientId);
        const response = await axios.get(`/patients/${patientId}/dependents`);
        this.dependent = response.data;
      } catch (error) {
        console.error("Error fetching dependents:", error);
      }
    },
    transformGender(gender) {
      return gender === "M" ? "Male" : "Female";
    },
    editDependent(dependent) {
      if (dependent.dateof_birth) {
        dependent.dateof_birth = new Date(dependent.dateof_birth);
      }
      this.selectedDependent = {
        ...dependent,
        dateof_birth: dependent.dateof_birth || null
      };
    },
    async deactivateDependent(selectedDependent) {
      try {
        const updatedDependent = { ...selectedDependent, listyn: false };
        await axios.put(
          `/patients/${selectedDependent.patientid}`,
          updatedDependent
        );

        selectedDependent.listyn = false;
      } catch (error) {
        console.error("Error deactivating dependent:", error);
      }
    },
    async activateDependent(selectedDependent) {
      try {
        const updatedDependent = { ...selectedDependent, listyn: true };

        await axios.put(
          `/patients/${selectedDependent.patientid}`,
          updatedDependent
        );

        selectedDependent.listyn = true;
      } catch (error) {
        console.error("Error activating dependent:", error);
      }
    }
  },
  beforeMount() {
    this.fetchDependents();
  }
};
</script>
<style scoped>
.form-control[type="file"]:not(:disabled):not([readonly]) {
  padding: 10px;
}
</style>
