<template>
  <div class="login-body">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="login-content-info">
        <div class="container">
          <!-- Login Email Otp -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
              <div class="account-content">
                <div class="login-shapes">
                  <div class="shape-img-left">
                    <img src="@/assets/img/shape-01.png" alt="shape-image" />
                  </div>
                  <div class="shape-img-right">
                    <img src="@/assets/img/shape-02.png" alt="shape-image" />
                  </div>
                </div>
                <div class="account-info">
                  <div class="login-back">
                    <router-link to="" @click="$router.go(-1)"
                      ><i class="fa-solid fa-arrow-left-long" ></i> Back</router-link
                    >
                  </div>
                  <div class="login-verify-img">
                    <img src="@/assets/img/icons/email-icon.svg" alt="email-icon" />
                  </div>
                  <div class="login-title">
                    <h3>OTP Verification</h3>
                    <p class="mb-0">
                      OTP sent MobileNumber <strong>{{ this.maskemail }}</strong>
                    </p>
                  </div>
                  <p v-if="error">
                    <ul>
                      <li>{{ error }}</li>
                    </ul>
                  </p>
                  <form
                    method="get"
                    class="digit-group login-form-control"
                    data-group-name="digits"
                    data-autosubmit="false"
                    autocomplete="off"
                    @submit.prevent="handleSignUpConfirmation"
                  >
                  <div class="otp-boxe">
                      <div class="mb-3e">
                        <v-otp-input length="4" v-model="otp"
                           :loading="loading"
                           autofocus >
                        </v-otp-input>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="otp-info">
                        <div class="otp-code">
                          <p>Didn't receive OTP code?</p>
                          <v-btn @click="resendCode" :disabled="countDown>0" variant="outlined">Resend Code</v-btn>
                        </div>
                        <div class="otp-sec">
                          <p><i class="feather-clock"></i>  {{ countDown }} secs</p>
                        </div>
                      </div>
                    </div>
                    <div class="text-end">
                      <router-link class="forgot-link" to="register"
                        >Change the signup details?</router-link
                      >
                    </div>
                    <div class="reset-btn">
                      
                        <b-button :disabled="otp.length < 4 || loading"
                          class="btn btn-primary w-100 btn-lg login-btn"
                          type="submit"
                        ><span
                          class="spinner-border spinner-border-sm me-2"
                          role="status" v-if="loading"
                        ></span
                        >
                          Submit
                        </b-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- /Login Email Otp -->
        </div>
      </div>
      <!-- /Page Content -->
      <cursor></cursor>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import { ref, onMounted } from "vue";
// import { confirmSignUp, resendSignUpCode, adminConfirmSignUp} from 'aws-amplify/auth';
import { signUp } from 'aws-amplify/auth';

export default {
  data() {
    return {
      countDown: 30,
      error: null,
      otp: '',
      loading: false,
      maskemail: localStorage.getItem("phone"),
      email: localStorage.getItem("signupemail"),
      phone: localStorage.getItem("signupphone"),
      given_name: localStorage.getItem("givenname"),
      family_name: localStorage.getItem("familyname"),
      password: localStorage.getItem("password"),
      gender: localStorage.getItem("gender"),
    } 
  },
  methods: {
    countDownTimer () {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
    },
    async sendWelcomeEmail(patientId) {
      console.log(patientId);
      // const patientid = localStorage.getItem("signuppatientid")
      await axios.post(`/patients/${patientId}/welcome`, {});
    },
    
    async handleSignUpConfirmation() {
      this.loading = true;
      this.error = null;
      try {
        // Step 1: Validate OTP
        const validateResponse = await axios.post("/otp/validator", {
          email: this.email,
          mobileNumber: this.phone,
          otpCode: this.otp,
        });

        if (validateResponse) {
          // Step 2: Sign-up in AWS Cognito
          const { isSignUpComplete, userId } = await signUp({
            username: this.phone,
            password: this.password,
            options: {
              userAttributes: {
                email: this.email,
                phone_number: this.phone,
                given_name: this.given_name,
                family_name: this.family_name,
              },
              autoSignIn: false,
            },
          });

          // Step 3: If sign-up is complete, create patient details
          if (isSignUpComplete) {
            const patientDetails = {
              firstname: this.given_name,
              lastname: this.family_name,
              contactnumbers: this.phone,
              email: this.email,
              gender: this.gender,
              idp_id: userId, 
              listyn: true,
            };

            const response = await axios.post(`/patients`, patientDetails);

            if (response) {
              const patientId = response.data;
              
              // Step 4: Send a welcome email after patient creation

              await this.sendWelcomeEmail(patientId);
              this.$router.push("/signup-success");
            } else {
              this.error = "Failed to create patient details.";
            }
          } else {
            this.error = "Failed to confirm sign-up.";
          }
        } else {
          this.error = "Invalid OTP. Please try again.";
        }
      } catch (e) {
        this.loading = false;
        console.log("Error confirming sign-up", e);
        this.error = e.message || "Something went wrong. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    async resendCode() {
      try {
        // resendSignUpCode({
        //   username: this.username
        // });
        await axios.post('/otp', {
            email: this.email,
            mobileNumber: this.phone,
          });
        this.countDown=30;
        this.countDownTimer();
      } catch (e) {
        console.log(e);
      }
    }
  },
  created () {
      this.countDownTimer()
  },
};
</script>
