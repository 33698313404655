<template>
  <div class="tab-pane fade" id="pres">
    <div class="text-end">
  <button @click="openModal()" class="add-new-btn">
    Add Prescription
  </button>
</div>

    <div class="card card-table mb-0">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in prescriptions" :key="item.prescripid">
                <td>{{ item.formattedDate }}</td>
                <td class="text-end">
                  <div class="table-action">
                    <a
                      href="javascript:void(0);"
                      @click="printPrescription(item.prescripid, item.appointmentid)"
                      class="btn btn-sm bg-primary-light me-2"
                    >
                      <i class="fas fa-print"></i> Print
                    </a>
                    <a href="javascript:void(0);" 
                    @click="viewPrescription(item.prescripid)"
                    class="btn btn-sm bg-info-light">
                      <i class="far fa-eye"></i> View
                    </a>
                    <!--router-link :to="{ name: 'edit-prescription', params: { id: item.prescripid } }"
                      class="btn btn-sm bg-warning-light"
                    >
                      <i class="fas fa-edit"></i> Edit
                    </router-link-->
                    <a
                      href="javascript:void(0);"
                      @click="deletePrescription(item.prescripid)"
                      class="btn btn-sm bg-danger-light"
                    >
                      <i class="fas fa-trash"></i> Delete
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Prescription Modal -->
<div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Prescription</h2>  
        <div v-if="isAddPrescription" class="appointment-dropdown">
        <label for="appointment-select">Select Appointment:</label>
        <select v-model="selectedAppointmentId" id="appointment-select">
          <option disabled value="">-- Select an appointment --</option>
          <option v-for="appointment in pastAppointments" :key="appointment.slno" :value="appointment.slno">
            {{ appointment.appointmenttime }} - {{ appointment.appointmentdate }}
          </option>
        </select>
        
      </div>

<div v-if="isAddPrescription" >
        <button @click="addPrescription(selectedAppointmentId)">Create</button>
        <button  class="deletebutton" @click="closeModal"> ✕ </button>
        </div>
        <div v-if="!isAddPrescription" >
        <button @click="closeModal"> ✕ </button></div>

      </div>
      <div class="modal-body">

        
      
        <div class="medicine-list-container">
        <div class="search-bar">
          <input type="text" placeholder="Search medicines..." v-model="search" />
          <i class="fa fa-search"></i>
        </div>
        
        <draggable  v-model="filteredMedicines" class="medicine-list"  :group="{ name: 'prescription', pull: true, put: false }" item-key="medicinename">
           <template #header>
      <h3> Medicine List</h3> 
    </template>
          <template #item="{ element }">
            <div class="medicine-item">
              <div class="medicine-details">
                <p><strong>{{ element.medicinename }}</strong> ({{ element.brandname }})</p>
                <p>{{ element.dosage }}</p>
              </div>
              <!--button @click="addToPrescription(element)">+</button-->
            </div>
          </template>
        </draggable>
        </div>
        <div class="prescription-list">
          <draggable v-model="prescription" class="prescription-list" :group="{ name: 'prescription', pull: false, put: true }" item-key="prescripmedid"  @add="onAddToPrescription">
    <template #header>
      <h3>Prescription List</h3>
    </template>
    <template #item="{ element }">
      <div class="medicine-item">
        <div class="medicine-details">
          <div class="edit-section">
            <div v-if="!element.isEditing">
              <p><strong>{{ element.medicinename }}</strong> ({{ element.brandname }})    <span @click="toggleEdit(element)">
  <i class="fas fa-pencil-alt"></i> Edit
</span>
</p>
              <p>{{element.frequency}}, {{element.duration}} days </p>  
              
            </div>
            <div v-if="element.isEditing">
              <input v-model="element.medicinename" placeholder="Medicine Name" />
              <input v-model="element.brandname" placeholder="Brand Name" />
              <input v-model="element.dosage"    placeholder="Type, Strength (mg)" />
              <input v-model="element.frequency" placeholder="Frequency" />
              <button @click="saveEdit(element)">
                Save
              </button>
          

          <!-- Frequency Buttons -->
          <!--div class="frequency-buttons">
            <button
              v-for="time in ['Morning', 'Afternoon', 'Night']"
              :key="time"
              :class="{'active': element.frequency && element.frequency.includes(time)}"
              @click="toggleFrequency(element, time)"
            >
              {{ time }}
            </button>
          </div-->

          <!-- Meal Toggle -->
          <!--div class="meal-toggle">
            <span @click="toggleMeal(element, 'before')" :class="{ active: element.meal === 'before' }">Before Food</span> | 
            <span @click="toggleMeal(element, 'after')" :class="{ active: element.meal === 'after' }">After Food</span>
          </div-->

  <!-- duration-->
  <div class="duration-field">
    
                  <p> No. of Days:  <input
                        type="number" 
                        v-model="element.duration"
                        class="duration-input"
                        placeholder="1"     
                      /></p>
                    </div>
            </div>
          </div>
        <!-- Note Section -->
  <div>
    <span @click="toggleNoteSection(element)">
      <i class="fas fa-pencil-alt"></i> <!-- Small pencil icon -->
      {{ element.showNote ? 'Close Note' : 'Open Note' }}
    </span>
    <textarea v-if="element.showNote" v-model="element.note"></textarea>
  </div>
        </div>

        <!-- Delete Button -->
        <button class="delete" @click="removeFromPrescription(element)"> ✕ </button>
      </div>
    </template>
  </draggable>

        </div>
        </div>  
        <div v-if="isAddPrescription" class="modal-footer">
          <!--button @click="AddPrescription(appointment.slno)">Create</button-->
          <button @click="closeModal">Close</button>
        </div>
        <div v-if="!isAddPrescription" class="modal-footer">
          <button @click="createPrescription">Save</button>
          <button @click="closeModal">Close</button>
        </div>
      </div>
    </div>

</template>

<script>
import axios from "axios";
import draggable from 'vuedraggable';
import jsPDF from "jspdf";

export default {
   props: {
    patientid: { 
      type: Number,
      required: true
    }
  },
  data() {
    return {
      prescriptions: [],
      prescription: [],
      isModalOpen: false,
      isEditingNote: false,
      prescripId: null,
      newNote: '',
      medicines: [],
      medicine: [], 
      prescription_exists: false,
      isAddPrescription: false,
    pastAppointments: [],  
    selectedAppointmentId: null,
    };
  },
  components: {
draggable,
  },
  computed: {
    filteredMedicines() {
      return this.medicines.filter(item => {
        return !this.search || 
               item.medicinename.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
      async fetchMedicines() {
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        this.DoctorId = doctorId;
        const response = await axios.get(`/doctors/${this.DoctorId}/medicines`);
         this.medicine = response.data.map(med => {
          const [frequencyValue, durationValue] = (med.defaultdose || "").split(",");
           const numericDuration = durationValue ? parseInt(durationValue.match(/\d+/), 10) : 5;
      return {
        medicinename: med.medicinename,
        brandname: med.brandname,
        dosage: `${med.medicinetype}, ${med.medstrength}`,
        frequency: frequencyValue || null,  
         duration: numericDuration || 5,
      };
    });

  
      } catch (error) {
        console.error("Error fetching medicines:", error);
      }
    },
    async fetchPrescriptions() {
      try{

      const response = await axios.get(`/patients/${this.patientid}/prescriptions`);
      this.prescriptions = response.data;
      for (const item of this.prescriptions) {
        item.formattedDate = await this.formatDate(item.appointmentid);
      }
      }
        catch(error) {
          console.error("Error fetching prescriptions:", error);
        }
    },
    handleDrop(event) {
    const droppedElement = event.item;
    const newElement = this.initializePrescriptionItem(droppedElement);
    this.prescription.push(newElement);
  },
  async viewPrescription(prescripId) {
    try {
      const response = await axios.get(`/patients/1/prescriptions/${prescripId}/medications`);

       this.prescription = response.data;
      console.log("fetched medicines:",this.prescription);
       this.medicines = this.medicine.filter(medicine => {
      return !this.prescription.some(prescripItem => 
        prescripItem.medicinename === medicine.medicinename && 
        prescripItem.brandname === medicine.brandname
      );
    });

      this.openModal(prescripId);

    } catch (error) {
      console.error("Failed to fetch prescription medications:", error);
      
    }
  },
   onAddToPrescription(event) {
    const addedMedicine = event.clone;
    const isAlreadyInPrescription = this.prescription.some(
      med => med.medicinename === addedMedicine.medicinename && med.brandname === addedMedicine.brandname
    );
    event.preventDefault();
    if (isAlreadyInPrescription) {
      console.log("This medicine is already in the prescription.");
    }
    this.$nextTick(() => {
      event.item.remove();
    });
  },
  
   async printPrescription(prescripId, appointmentId) {
    
    const apptdata = await this.fetchApptDetails(appointmentId);
    console.log(apptdata);
    const prescriptionData = await this.fetchPrescipDetails(prescripId); 
    const formattedDate = await this.formattedDate(apptdata.date_and_time);

    try {
    
    const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.style.border = 'none';
      document.body.appendChild(iframe);
      const doc = iframe.contentWindow.document;
      
      const htmlContent = `
        <html>
        <head>
          <title>Prescription</title>
          <style>
            /* Add your custom CSS here if needed */
            .modal-body { padding: 20px; font-family: Arial, sans-serif; }
            .doctor-clinic-info, .prescription-header { margin-bottom: 20px; }
            .rx-symbol { font-size: 24px; margin: 10px 0; display: block; }
            .table-striped {
  width: 100%;
  margin-top: 20px;
 
}

.table-striped th,
.table-striped td {
  text-align: left;
  padding: 8px;
}

.table-striped th {
  white-space: nowrap;
}
            
.prescription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
          </style>
        </head>
        <body>
          <div class="modal-body">
            <div class="doctor-clinic-info">
              <p><strong>Dr. ${apptdata.doctor_name}</strong></p>
              <p>${apptdata.speciality}</p>
              <p>${apptdata.clinic_name}</p>
            </div>
            <div class="prescription-header">
              <span><strong>Date: </strong>${formattedDate}</span>
              <span class="patient-name"><strong>Patient: </strong>${apptdata.patient_name}</span>
            </div>
            <span class="rx-symbol">℞</span>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Medicine Name</th>
                  <th>Brand Name</th>
                  <th>Dosage</th>
                  <th>Frequency</th>
                  <th>Duration</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                ${prescriptionData.map(medication => `
                  <tr>
                    <td>${medication.medicinename}</td>
                    <td>${medication.brandname}</td>
                    <td>${medication.dosage}</td>
                    <td>${medication.frequency}</td>
                    <td>${medication.duration}</td>
                    <td>${medication.note}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          </div>
        </body>
        </html>
      `;

      doc.open();
      doc.write(htmlContent);
      doc.close();
      iframe.onload = function () {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();

        document.body.removeChild(iframe);
      };
      
    } catch (error) {
      console.error("Error printing prescription:", error);
    }
  },
  
  async fetchApptDetails(slno){
    try{
      const response = await axios.get(`/patients/${this.patientid}/appointments`);
      const appointments = response.data.past;
    const matchingAppointment = appointments.find(appt => appt.appointment_id === slno);
    
    return matchingAppointment ? matchingAppointment : null;
  } catch (error) {
    console.error("Error fetching appointment details:", error);
    return null;
  }
  },
  async fetchPrescipDetails(prescripId){
    try{
      const response = await axios.get(`/patients/1/prescriptions/${prescripId}/medications`);
      return response.data;
    }
    catch(error){
      console.error(error);
    }
  },
  async formattedDate(dateString) {
  const date = new Date(dateString); 

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear(); 
  
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; 

  return `${month} ${day}, ${year}, <b>Time: </b>${hours}:${minutes} ${ampm}`;

},
  async fetchPastAppointments() {
    try {
      const doctorId = sessionStorage.getItem("userdbid");
        this.DoctorId = doctorId;
      const response = await axios.get(`/patients/${this.patientid}/appointmentslist/${this.DoctorId}`);
      
      this.pastAppointments = await this.filterPastAppointments(response.data.appointmentlist);
    console.log("final apptslist:", this.pastAppointments);
  } catch (error) {
    console.error("Error fetching past appointments:", error);
  }
},
async filterPastAppointments(pastAppointmentlist){
  try{
    const pastAppointments = pastAppointmentlist.filter(appointment => appointment.appointmentstatus === 'Completed');
    console.log("Filtered Completed Appointments:", pastAppointments);
    
    const promises = pastAppointments.map(async (appointment, index) => {
      const prescripid = await this.checkPrescription(appointment);
      pastAppointments[index].prescripid = prescripid; 
    });

    await Promise.all(promises);
    console.log("Appointments with Prescription Status:", pastAppointments);
    const filteredAppointments = [];
    let count = 0;

    for (const appointment of pastAppointments) {
      if (appointment.prescripid === null) {
        filteredAppointments.push(appointment);
        count++;
        if (count >= 5) {
          break;
        }
      }
    }

   return filteredAppointments;
  }
  catch(error){
    console.error(error);
  }
},
async checkPrescription(appointment) {
  try {
    
    const response = await axios.get(`/patients/1/appointments/${appointment.slno}/prescriptions`);
    
    return response.data && response.data[0] && response.data[0].prescripid ? response.data[0].prescripid : null;

  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log(`No prescription found for appointment ${appointment.slno}`);
      return null;  
    } else {
      console.error(`Error checking prescription for appointment ${appointment.slno}:`, error);
      return null; 
    }
  }
},
async openModal(prescripId = null) {
    this.prescripId = prescripId;
    this.$emit('openModal');
    this.isModalOpen = true;
    
    if (prescripId) {
      this.isAddPrescription = false;
    } 
    else {
      this.isAddPrescription = true;
      this.prescription =[];
      this.medicines = this.medicine;
      await this.fetchPastAppointments(); 
    }
},
closeModal() {
    this.isModalOpen = false;
    this.$emit('closeModal');
    this.isAddPrescription = false;  
    this.pastAppointments = [];      
},
  async removeFromPrescription(element) {
    const index = this.prescription.indexOf(element);


    if (element.prescripmedid && element.prescripmedid !== 0) {
      try {
        await this.deleteMedicationFromServer(element.prescripmedid);
        if (index !== -1) {
          this.prescription.splice(index, 1);
        }
        this.medicines.push(element);
      } catch (error) {
        console.error("Failed to delete medication:", error);
      }
    } else {
     
      if (index !== -1) {
        this.prescription.splice(index, 1);
        this.medicines.push(element);
      }
    }
  },
  async deleteMedicationFromServer(prescripmedid) {
    try {
     const response = await axios.delete(`/patients/1/medications/${prescripmedid}`);

     } catch (error) {
      console.error("Failed to delete medications:", error);
      
    }
  },
    onDragEnd(evt) {  
      const draggedItem = this.medicines[evt.oldIndex];
      if (evt.from === evt.to) {
      } else {
        this.addToPrescription(draggedItem);
        this.filteredMedicines = this.filteredMedicines.filter(med => med.medicinename !== movedItem.medicinename);
        console.log(this.filteredMedicines);
      }
    },
    async addPrescription(appointmentid) {
    try {
      const response = await axios.post(`/patients/${this.patientid} /appointments/${appointmentid}/prescriptions`, {
        appointmentid: appointmentid,
        prescrippath: 'default-path', 
        note: '', 
        status: 'A' });
        this.prescripId = response.data;
        this.createPrescription();
        } catch (error) {
      console.error("Failed to create prescription:", error);
    }
  },
    async createPrescription() {
    try {
      const newMedications = this.prescription
      .filter(med => !med.prescripmedid) 
      .map(med => {
        //const [frequencyValue, durationValue] = (med.frequency || "").split(","); // Split frequency and duration
        return {
          prescripmedid: 0, 
          medicinename: med.medicinename,
          brandname: med.brandname,
          dosage: med.dosage,
          frequency: med.frequency, 
          duration: med.duration, 
          note: med.note || "a/f food"
        };
      });

    const editedMedications = this.prescription
      .filter(med => med.prescripmedid && med.isEdited) // Check for edited medicines
      .map(med => {
        const [frequencyValue, durationValue] = (med.frequency || "").split(","); // Split frequency and duration
        return {
          prescripmedid: med.prescripmedid,
          medicinename: med.medicinename,
          brandname: med.brandname,
          dosage: med.dosage,
          frequency: med.frequency, 
          duration: med.duration, 
          note: med.note || "a/f food"
        };
      });
    const medications = [...newMedications, ...editedMedications];
    console.log("med:", medications);
   const response = await axios.post(
        `/patients/1/prescriptions/${this.prescripId}/medications`,
        medications,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    console.log("Prescription created successfully:", response.data);
      this.fetchPrescriptions();
      this.closeModal();
      
    } catch (error) {
      console.error("Failed to add medications:", error);
    }
  },addNote() {
    this.isEditingNote = true;
  },
  saveNote() {
    if (this.newNote.trim()) {
      this.$set(this.element, 'note', this.newNote);
      this.isEditingNote = false;
    }
  },
  editNote() {
    this.newNote = this.element.note;
    this.isEditingNote = true;
  },
  saveEdit(med) {
    med.isEditing = false;
    med.isEdited = true; // Mark this medicine as edited
  },
  toggleEdit(med) {
    med.isEditing = true;
  },
    async deletePrescription(prescripid) {
       try {
     const response = await axios.delete(`/patients/1/prescriptions/${prescripid}`);
     this.fetchPrescriptions();

     } catch (error) {
      console.error("Failed to delete Prescription:", error);
      
    }
    },
    async formatDate(appointmentid) {
      try{
    const response = await axios.get(`/patients/${this.patientid}/appointments/${appointmentid}`);
    const dateString = response.data.appointmentdate;
    const timeString = response.data.appointmenttime;
const dateOptions = { year: "numeric", month: "short", day: "numeric" };
 const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const combinedDateTime = new Date(`${dateString}T${timeString}`);

    const datePart = combinedDateTime.toLocaleDateString("en-US", dateOptions);
    const timePart = combinedDateTime.toLocaleTimeString("en-US", timeOptions);

  return `${datePart}, ${timePart}`;
      }
      catch(error){
        console.error(error);
      }
},
  },
  beforeMount() {
    this.fetchPrescriptions(); 
    this.fetchMedicines();
  },
};
</script>
<style scoped>
 @media print {
    .prescription-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .patient-name {
      float: right;
    }

    .prescription-table {
      width: 100%;
      border-collapse: collapse;
    }

    .prescription-table th, .prescription-table td {
      padding: 10px;
      text-align: left;
    }

    
    .prescription-table tbody tr:nth-child(odd) {
      background-color: #f0f0f0; 
    }

    .prescription-table tbody tr:nth-child(even) {
      background-color: #d9d9d9;
    }

    
    .prescription-table, .prescription-table th, .prescription-table td {
      border: none; 
    }
  }
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 900px;
  padding: 15px;
  max-height: 95vh;
  min-height: 95vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-body {
  display: flex;
  gap: 20px;
  flex-grow: 1;
}

.medicine-list, .prescription-list {
  flex: 1;
  max-height: 65vh;
  min-height: 65vh; /* Fixed height */
  overflow-y: auto;  /* Scrollable when items exceed height */
  padding: 5px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.medicine-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}
.medicine-item:hover {
  background-color: #e9e9e9;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.nav-link {
  background-color: #d3d9df;
  border-color: #ced4da;
  margin-right: 1rem;
  transition: background-color 0.3s;
  margin: 5px 0;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
    margin-right: .15rem;
}

.nav-link:hover {
  background-color: #0056b3;
}

.modal-content .btn {
  cursor: auto;
}

.custom-badge {
  background-color: #708090;
  color: white;
}
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}
.deletebutton {
  background-color: red; 
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}
.edit-icon {
  cursor: pointer;
  color: #007bff;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.frequency-buttons button {
  border: 1px solid #007bff;
  background: white;
  color: #007bff;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.frequency-buttons button.active {
  background-color: green;
  color: white;
}

.meal-toggle span {
  cursor: pointer;
  color: #007bff;
  margin-right: 10px;
}

.meal-toggle span.active {
  font-weight: bold;
  color: green;
}

textarea {
  width: 100%;
  padding: 5px;
  margin-top: 10px;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  font-size: 12px;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-bar input {
  flex: 1;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-bar i {
  margin-left: 5px;
  color: #888;
}
.medicine-list-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.prescription-list-container {
  display: flex;
  color: #000;
  flex-direction: column;
  width: 50%;
}
.duration-input{
  width: 35px;
}
.download-text {
  font-size: 12px;
  display: inline; 
  margin: 0; 
  margin-right: 5px;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); */
  font-weight: bold;
}

/* pdf and excel icon */
.export-icon {
  width: 30px;
  height: 30px;
}
 /* swirl alert close button css*/
 .swal-popup-custom {
    position: relative;
  }

  .swal-close-button-custom {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: #d33;
  }
  .appointment-dropdown {
  margin-bottom: 15px;
}

.appointment-dropdown select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

</style>