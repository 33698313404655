<template>
  <div class="tab-pane fade" id="pat_prescriptions">
    <div class="card card-table mb-0">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Date</th>
                <th>Clinic Name</th>
                <th>Doctor Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pastAppointments" :key="item.appointment_id">
                <td>
                  <h2 class="table-avatar">
                    <span class="avatar avatar-sm me-2">
                      <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
                    </span>
                    {{ item.patient_name }}
                  </h2>
                  <br />
                  <a
                  v-if="item.prescripid"
                    href="javascript:void(0);"
                    class="btn btn-sm bg-info-light"
                    @click="viewPrescription(item.prescripid, item)"
                  >
                    <i class="far fa-eye"></i> View Prescription
                  </a>
                </td>
                <td>{{ formatDate(item.date_and_time) }}</td>
                <td>{{ item.clinic_name }}</td>
                <td>
                  <h2 class="table-avatar">
                    <router-link :to="'doctor/' + item.doctor_id" class="avatar avatar-sm me-2">
                      <img
                        class="avatar-img rounded-circle"
                        :src="//item.doctor_image ? require('@/assets/img/doctors/'+item.doctor_image) : 
                        require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        alt="Doctor Image"
                      />
                    </router-link>
                    <router-link :to="'doctor/' + item.doctor_id">
                      {{ item.doctor_name }}
                      <span>{{ item.speciality }}</span>
                    </router-link>
                  </h2>
                </td>
                <td class="text-end">
                  <div class="table-action">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-sm bg-info-light"
                      data-bs-toggle="modal"
                      data-bs-target="#appointment_medical_records_modal"
                      @click="addMedicalRecords(item.appointment_id, item.patientid, item.doctor_id)"
                    >
                      <i class="fas fa-file-medical"></i> Add Medical Record
                    </a>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm bg-info-light ms-1"
                      data-bs-toggle="modal"
                      data-bs-target="#view_medical_records_modal"
                      @click="viewMedicalRecords(item.patientid, item.appointment_id, item.doctor_id)"
                    >
                      <i class="far fa-eye"></i> View Records
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
<div v-if="isViewModalOpen" class="modal-overlay" @click.self="closeViewModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Prescription</h2>
        <button @click="closeViewModal">✕</button>
      </div>

      <div class="modal-body">
        <div class="doctor-clinic-info">
          <p><strong>Dr. {{ apptdata.doctor_name }}</strong></p>
          <p>{{ apptdata.speciality }}</p>
          <p>{{ apptdata.clinic_name }}</p>
        </div>

        <div class="prescription-header">
          <span><strong>Date: </strong>{{ formatDate(apptdata.date_and_time) }}</span>
          
          <span><strong>Patient: </strong>{{ apptdata.patient_name }}</span>
        </div>
<span class="rx-symbol">℞</span>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Medicine Name</th>
              <th>Brand Name</th>
              <th>Dosage</th>
              <th>Frequency</th>
              <th>Duration</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="medication in prescriptionData" :key="medication.medicinename">
              <td>{{ medication.medicinename }}</td>
              <td>{{ medication.brandname }}</td>
              <td>{{ medication.dosage }}</td>
              <td>{{ medication.frequency }}</td>
              <td>{{ medication.duration }}</td>
              <td>{{ medication.note }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer">
         <button @click="printPrescription">Print</button>
        <button @click="downloadPrescriptionPDF">Download as PDF</button>
        <button @click="closeViewModal">Close</button>
      </div>
    </div>
  </div>


</template>
<script>
import axios from "axios";
import jsPDF from "jspdf";
export default {
  props: {
    pastAppointments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isViewModalOpen: false, 
      prescriptionData: [],
      patientName: '',
      doctorName: '',
      showPrescriptionModal: false,
    };
  },
  methods: {
    async viewPrescription(prescripid, appt_details) {
      try {
        const response = await axios.get(`/patients/1/prescriptions/${prescripid}/medications`);
        this.prescriptionData = response.data;
        if(response){
       this.openViewModal(appt_details, this.prescriptionData);
        }
      } catch (error) {
        console.error("Error fetching prescription data:", error);
      }
    },
    openViewModal(appt_details, prescription) {
    this.apptdata = appt_details
    this.prescriptionData = prescription;
    this.$emit('openModal'); 
    this.isViewModalOpen = true; 
  },
  closeViewModal() {
    this.$emit('closeModal');
    this.isViewModalOpen = false;
  },
    formatDate(dateTimeString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateTimeString).toLocaleDateString("en-US", options);
    },
    addMedicalRecords(appointmentId, patientId, doctorId) {
      this.$emit('add-medical-records', { appointmentId, patientId, doctorId });
    },
    viewMedicalRecords(patientId, appointmentId, doctorId) {
      this.$emit('view-medical-records', { patientId, appointmentId, doctorId });
    },

  printPrescription() {
      window.print();
    },
    downloadPrescriptionPDF() {
  const doc = new jsPDF();
  
  doc.setFontSize(14);
  doc.text(`Prescription for ${this.apptdata.patient_name}`, 10, 10);
  
  doc.setFontSize(12);
  doc.text(`Doctor: Dr. ${this.apptdata.doctor_name}`, 10, 20);
  doc.text(`Clinic: ${this.apptdata.clinic_name}`, 10, 30);
  doc.text(`Date: ${this.formatDate(this.apptdata.date_and_time)}`, 10, 40);
  
  const tableColumn = ["Medicine Name", "BrandName", "Dosage", "Frequency", "Duration", "Note"];
  const tableRows = this.prescriptionData.map((med) => [
    med.medicinename,
    med.brandname,
    med.dosage,
    med.frequency,
    med.duration,
    med.note || "-"
  ]);
  
  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 50,
    theme: 'plain',
    headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255] }, 
    margin: { top: 50 },
    styles: {
      fontSize: 10,
      cellPadding: 3,
    },
    columnStyles: {
      0: { cellWidth: 'auto' },
      1: { cellWidth: 'auto' },
      2: { cellWidth: 'auto' },
      3: { cellWidth: 'auto' },
      4: { cellWidth: 'auto' },
      5: { cellWidth: 'auto' },
    }
  });
  
  // Download PDF
  doc.save(`prescription_${this.apptdata.patient_name}.pdf`);
}

  }
};
</script>
<style scoped>
@media print {
  body * {
    visibility: hidden;
  }
  
  .modal-content, .modal-content * {
    visibility: visible;
  }
  
  .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
  }
}
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px; 
  width: 850px;
  border-radius: 8px;
  position: relative;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  margin-top: 20px;
   overflow-y: auto;
  max-height: 300;
  max-height: 69vh;
  min-height: 69vh;

}

.doctor-clinic-info p {
  margin: 0;
}

.prescription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.rx-symbol {
  font-size: 24px;
  font-weight: bold;
}

.table-striped {
  width: 100%;
  margin-top: 20px;
 
}

.table-striped th,
.table-striped td {
  text-align: left;
  padding: 8px;
}

.table-striped th {
  white-space: nowrap;
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

button {
  padding: 8px 16px;
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>