<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <profilesidebar></profilesidebar>
            </div>
          </div>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <!-- Profile Settings Form -->
                <form @submit.prevent="updatePatientDetails">
                  <div class="row">
                    <div class="col-12 col-md-12">
                      <div class="mb-3">

                        <b-alert v-if="errorvalidation" v-model="showDismissibleAlert" variant="danger" dismissible>
                          {{ errorvalidation
                          }}
                        </b-alert>

                        <b-alert v-if="successMessage" v-model="showDismissibleAlert" variant="success" dismissible>
                          {{ successMessage
                          }}
                        </b-alert>


                        <div class="change-avatar">
                          <div class="profile-img">
                            <img :src="profileImageUrl || defaultImageUrl" alt="User Image" />
                          </div>
                          <div class="upload-img">
                            <div class="change-photo-btn">
                              <span>
                                <i class="fa fa-upload"></i> Upload Photo
                              </span>
                              <input type="file" ref="fileInput" class="upload" @change="uploadImage"
                                accept="image/*" />
                            </div>
                            <small class="form-text text-muted">Allowed JPG, JPEG or PNG. Max size of 1MB</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">First Name</label>
                        <input type="text" class="form-control" v-model="patientDetails.firstname" maxlength="25"
                          @input="validateName" :class="{ 'is-invalid': nameError }" />
                        <div class="invalid-feedback" v-if="nameError">{{ nameError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Last Name</label>
                        <input type="text" class="form-control" v-model="patientDetails.lastname" maxlength="25"
                          @input="validateLastname" :class="{ 'is-invalid': lnameError }" />
                        <div class="invalid-feedback" v-if="lnameError">{{ lnameError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Gender</label>
                        <div id="app">
                          <select class="form-control mb-2" v-model="patientDetails.gender" @input="validateGender"
                            :class="{ 'is-invalid': genderError }">
                            <option value="">
                              Select a gender type</option>
                            <option v-for="(label, value) in genders" :key="value" :value="value">{{ label }}</option>
                          </select>
                        </div>

                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Date of Birth</label>
                        <div class="cal-icon position-relative">
                          
                          <datepicker v-model="patientDetails.dateof_birth" placeholder="Date"
                            class="form-control datetimepicker" :editable="true" :clearable="false"
                            :input-format="dateFormat"
                            :class="{ 'is-invalid': dobError }" ></datepicker>
                            <i class="fa fa-times clear-icon" aria-hidden="true" @click="clearDateOfBirth"></i> 
                        </div>
                        <div class="invalid-feedback" v-if="dobError">{{ dobError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Age</label>
                        <input type="text" class="form-control" v-model="patientDetails.age" @input="validateAge"
                          :class="{ 'is-invalid': ageError }" maxlength="4" />
                          
                        <div class="invalid-feedback" v-if="ageError">{{ ageError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Email ID</label>
                        <input type="email" class="form-control" v-model="patientDetails.email" maxlength="50"
                          @input="validateEmail" :class="{ 'is-invalid': emailError }" />
                        <div class="invalid-feedback" v-if="emailError">{{ emailError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Mobile</label>
                        <input type="text" class="form-control" v-model="patientDetails.contactnumbers" maxlength="50"
                          @focus="validateContact" :class="{ 'is-invalid': contactError }" disabled />
                        <div class="invalid-feedback" v-if="contactError">{{ contactError }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Address</label>
                        <textarea class="form-control" v-model="patientDetails.address" rows="2"
                          maxlength="250"></textarea>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Areacode</label>
                        <input type="text" class="form-control" v-model="patientDetails.areacode" maxlength="10"
                          @input="validateAreacode" :class="{ 'is-invalid': areaError }" />
                        <div class="invalid-feedback" v-if="areaError">{{ areaError }}</div>
                      </div>
                    </div>
                    <!-- clarance -->
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Area</label>
                        <div id="app">
                          <select class="form-control mb-2" v-model="patientDetails.area"
                            @change="checkForCustomCategory">
                            <option value="" disabled selected>
                              Select a area</option>
                            <option value="custom">Add Custom
                              area</option>
                            <option v-if="patientDetails.area && !areax.includes(patientDetails.area)"
                              :value="patientDetails.area">
                              {{ patientDetails.area }}
                            </option>
                            <option v-for="(label, value) in areax" :key="value" :value="label">{{ label }}</option>
                            <!-- <option v-for="categories in areax" :key="categories" :value="categories">{{
                              categories }}
                            </option> -->
                          </select>
                          

                        </div>
                      </div>
                      <div v-if="isAddingCustomCategory" class="mt-2">
                        <input type="text" class="form-control" v-model="this.patientDetails.area" maxlength="50"
                          placeholder="Enter new area" />
                        <button class="btn btn-primary mt-2" @click="addCustomCategory">Add
                          Area</button>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <b-button type="submit" class="btn btn-primary submit-btn">Save Changes</b-button>
                  </div>
                </form>
                <!-- /Profile Settings Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <indexfooter></indexfooter>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import defaultImageUrl from "@/assets/img/patients/patient.jpg";
import eventBus from "@/eventBus";
import cities from "@/assets/json/cities.json";

export default {
  data() {
    return {
      title: "Profile Settings",
      text: "Home",
      text1: "Profile Settings",
      patientDetails: {
        email: "",
        contactnumbers: "",
        address: "",
        firstname: "",
        lastname: "",
        gender: "",
        dateof_birth: null,
        areacode: "",
        age: "",
        area: "",
      },
      genders: {
        M: 'Male',
        F: 'Female',
        O: 'Other'
      },
      areax: [],
      showDismissibleAlert: false,
      errorvalidation: "",
      nameError: "",
      lnameError: "",
      emailError: "",
      contactError: "",
      genderError: "",
      areaError: "",
      ageError: "",
      dobError: "",
      dateFormat: "dd-MM-yyyy",
      idpid: "",
      patientid: "",
      defaultImageUrl: defaultImageUrl,
      file: "",
      profileImageUrl: "",
      successMessage: "",
      isAddingCustomCategory: false,
      customArea: '',
    };
  },
  mounted() {
    this.fetchPatientDetails();
    this.areax = cities.map(city => city.City);
    if (this.patientDetails.area && Array.isArray(this.areax) && !this.areax.includes(this.patientDetails.area)) {
      this.areax.push(this.patientDetails.area);
    }

  },
  name: "profile-settings",
  methods: {

    validateName() {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(this.patientDetails.firstname)) {
        this.nameError = "Name should only contain alphabetical characters.";
        return false;
      } else {
        this.nameError = "";
        return true;
      }
    },
    validateLastname() {
      const regex = /^[a-zA-Z]*$/;
      if (!regex.test(this.patientDetails.lastname)) {
        this.lnameError =
          "Last name should only contain alphabetical characters.";
        return false;
      } else {
        this.lnameError = "";
        return true;
      }
    },
    validateAge() {
      const regex = /\d/;
      const age = this.patientDetails.age.trim();

      if (age === "" || regex.test(age)) {
        this.ageError = "";
        return true;
      } else {
        this.ageError = "The age must include at least one numeric digit.";
        return false;
      }
    },
    validateEmail() {
      const regex = /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.patientDetails.email)) {
        this.emailError = "Please enter a valid email address.";
        return false;
      } else {
        this.emailError = "";
        return true;
      }
    },
    validateGender() {
      if (!this.patientDetails.gender || this.patientDetails.gender.trim() === "") {
        this.genderError = "Please select a gender.";
        return false;
      } else {
        this.genderError = "";
        return true;
      }
    },
    validateContact() {
      this.contactError = "Mobile number cannot be modified.";
    },
    validateAreacode() {
      const regex = /^[0-9]*$/;
      if (!regex.test(this.patientDetails.areacode)) {
        this.areaError = "Area code should only contain numbers.";
        return false;
      } else {
        this.areaError = "";
        return true;
      }
    },
    validateDateOfBirth() {
      var dob = new Date(this.patientDetails.dateof_birth);
      var currentDate = new Date();
      if (dob > currentDate) {
        this.dobError = "Enter valid date of birth.";
        return false;
      } else {
        this.dobError = "";
        return true;
      }
    },
    // clarance
    clearDateOfBirth() {
      this.patientDetails.dateof_birth = null;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async fetchPatientDetails() {
      this.patientid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(`patients/${this.patientid}`);
        this.patientDetails = response.data;

        if (this.patientDetails.dateof_birth) {
          this.patientDetails.dateof_birth = new Date(
            this.patientDetails.dateof_birth
          );
        }
        this.fetchPatientProfileImage();
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    },
    checkForCustomCategory() {
      if (this.patientDetails.area === 'custom') {
        this.isAddingCustomCategory = true;
        this.patientDetails.area = '';
      } else {
        this.isAddingCustomCategory = false;
        this.patientDetails.area = this.patientDetails.area;
      }
    },
    addCustomCategory() {
      const trimmedCategory = this.patientDetails.area.trim();
      if (trimmedCategory && !this.areax.includes(trimmedCategory)) {
        this.areax.push(trimmedCategory);
        this.patientDetails.area = trimmedCategory;
        this.isAddingCustomCategory = false;

      }
    },
    async uploadImage(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        this.errorvalidation = "Only JPG, JPEG, or PNG images are allowed.";
        this.showDismissibleAlert = true;
        return;
      }
      const maxSize = 1 * 1024 * 1024;
      if (file.size > maxSize) {
        this.errorvalidation = "File size exceeds 1MB. Please choose a smaller file";
        this.showDismissibleAlert = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.successMessage = "";
        return;


      }



      const formData = new FormData();
      formData.append("image_file", file);

      try {
        const response = await axios.post(
          `patients/${this.patientid}/profile-image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );

        this.fetchPatientDetails();
        eventBus.emit('imageUploaded');
        this.successMessage = "Profile image uploaded successfully";
        this.showDismissibleAlert = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.errorvalidation = "";

      } catch (error) {

        this.errorvalidation = "Failed to upload image. Please try again.";
        this.showDismissibleAlert = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.successMessage = "";
      } finally {
        this.clearFileInput();
      }
    },

    clearFileInput() {
      this.$refs.fileInput.value = '';
    },



    async fetchPatientProfileImage() {
      try {
        const response = await axios.get(
          `/patients/${this.patientid}/profile-image`,
          {
            responseType: "arraybuffer"
          }
        );

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    },
    async updatePatientDetails() {
      try {
        if (!this.patientDetails.firstname) {
          this.errorvalidation = "The patient name cannot be empty";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.validateName()) {
          this.errorvalidation = "Please enter a valid Name";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.validateLastname()) {
          this.errorvalidation = "Please enter a valid last name";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.patientDetails.gender) {
          this.errorvalidation = "Please select a gender";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.validateDateOfBirth(this.newlySelectedDob)) {
          this.errorvalidation = "Please enter a valid date of birth";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.validateAge()) {
          this.errorvalidation = "Please enter a valid age";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        if (!this.validateEmail()) {
          this.errorvalidation = "Please enter a valid email address";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.validateAreacode()) {
          this.errorvalidation = "Please enter a valid areacode";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        const updatedPatientDetails = { ...this.patientDetails };
        if(this.patientDetails.dateof_birth !== null){
        updatedPatientDetails.dateof_birth = 
        this.formatDate(
          updatedPatientDetails.dateof_birth
        );

        }
        else
        {
          updatedPatientDetails.dateof_birth = null;
        }

        const response = await axios.put(
          `patients/${this.patientid}`,
          updatedPatientDetails
        );
        console.log("Patient details updated successfully:", response.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Patient details updated successfully!"
        });

        this.errorvalidation = "";

        await this.fetchPatientDetails();
      } catch (error) {
        console.error("Error updating patient details:", error);
        this.showDismissibleAlert = true;
        this.errorvalidation =
          "Failed to update patient details. Please check details entered.";
      }
    }
  }
};
</script>

<style scoped>
.b-alert-success {
  background-color: #4caf50;
  color: white;

}

.position-relative {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
  color: #dc3545;
}
.form-control:disabled {
  background-color: hsla(0, 0%, 94%, 0.462);
  color: black;
}

.v3dp__elements[data-v-65eb861b] button.selected span {
  background-color: #20c0f3 !important;
  color: aliceblue !important;
}

.v3dp__elements[data-v-65eb861b] button:not(:disabled):hover span {
  background-color: #20c0f3 !important;
  color: aliceblue !important;
}
</style>
