<template>
    <div class="main-wrapper tab-pane fade show active">
        <layoutheader></layoutheader>
        <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
        <div class="content">
            <div class="container">
                <div class="row">
                    <doctorsidebar></doctorsidebar>

                    <div class="col-md-7 col-lg-8 col-xl-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="col-md-12 col-lg-12 col-xl-12">
                                            <div>
                                                <b-alert v-model="showError" dismissible variant="danger">{{ error }}
                                                </b-alert>
                                            </div>
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h2>Medicines</h2>
                                                    <div class="alerts">
                                                        <b-alert v-model="showSuccessWarning" variant="warning"
                                                            dismissible>{{
                                                                successMessageWarning
                                                            }}</b-alert>
                                                        <b-alert v-model="showSuccessAlert" variant="success"
                                                            dismissible>{{ successMessage
                                                            }}</b-alert>
                                                        <b-alert v-model="showSuccessUpdate" variant="success"
                                                            dismissible>{{
                                                                successMessageUpdate
                                                            }}</b-alert>
                                                        <b-alert v-model="showSuccessUpdatex" variant="warning"
                                                            dismissible>{{
                                                                successMessageUpdatex
                                                            }}</b-alert>
                                                        <b-alert v-model="showSuccessDelete" variant="danger">{{
                                                            deleteMessage }}
                                                        </b-alert>
                                                    </div>
                                                </div>
                                               
                                                <div class="modal-body">
                                                    <div class="medicine-list-container">
                                                        <div class="search-bar">
                                                            <input type="text" placeholder="Search medicines..."
                                                                @input="handleMedicineNameChange" v-model="medName" 
                                                                maxlength="100"/>
                                                            <i class="fa fa-search"></i>
                                                        </div>


                                                        <draggable :list="pastxAppointmentfilter" class="medicine-list"
                                                            group="appointments" @end="onDrop('past', $event)"
                                                            :move="checkMove" tag="tbody">
                                                            <template #header>
                                                                <h3
                                                                    style="margin-top:10px; margin-bottom:10px; margin-left:5px">
                                                                    Medicine List</h3>
                                                            </template>
                                                            <template #item="{ element }">
                                                                <div class="medicine-item">
                                                                    <div class="medicine-details">
                                                                        <p><strong>Medicine name:</strong> {{
                                                                            element.medicinename }} ({{
                                                                                element.medstrength }}) </p>
                                                                        <p><strong>Brand name:</strong> {{
                                                                            element.brandname }}</p>
                                                                        <p><strong>Medicine type:</strong> {{
                                                                            element.medicinetype }} ({{ element.category
                                                                            }})</p>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </draggable>
                                                    </div>
                                                    <div class="prescription-list-container">
                                                        <div
                                                            style="display: flex; justify-content: space-between; align-items: center; ">
                                                            <div class="hover-textx"
                                                                style="font-weight: bold; transform: translateY(-50%); text-align:left; margin-top: 15px; margin-left: 20px;"
                                                                @click="toggleVisibility">
                                                                <i class="fa fa-plus" aria-hidden="true"
                                                                    style="margin-right: 3px;"></i>
                                                                Add
                                                            </div>
                                                            <div style="margin-right: 20px; margin-bottom: 10px; margin-top: 10px;"
                                                                class="d-flex align-items-center">
                                                                <!-- 
                                                                <span @click="removeAll" class="hover-text"
                                                                    style="margin-right: 10px; font-weight: bold">
                                                                    clear</span> -->
                                                                <div v-if="isDisable" class="text-endx"
                                                                    @click="confirmAll">
                                                                    <a class="btn btn-primary btn-sm">Save</a>
                                                                </div>
                                                                <div v-if="!isDisable" class="text-endx">
                                                                    <a class="btn btn-primary btn-sm disabled"
                                                                        aria-disabled="true">Save</a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <draggable :list="newAppointmentfilter"
                                                            class="prescription-list" group="appointments"
                                                            @end="onDrop('new', $event)" :move="checkMove">
                                                            <template #header>
                                                                <h3
                                                                    style="margin-top:10px; margin-bottom:10px; margin-left:5px">
                                                                    My medicine</h3>


                                                                <div v-if="newAppointmentfilter.length === 0 && isVisible !== true"
                                                                    style="text-align: center;">
                                                                    <h3
                                                                        style="margin: 0; margin-top: 100px;margin-bottom: 60px; opacity: 0.5;">
                                                                        Drag and drop here</h3>
                                                                </div>

                                                                <!-- update -->
                                                                <div v-if="isVisibleforedit">


                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormedit.medicinename"
                                                                                maxlength="100"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormedit.medicinename }">Medicine
                                                                                Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormedit.brandname"
                                                                                maxlength="100"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormedit.brandname }">Brand
                                                                                Name</label>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <div id="app">
                                                                                <select class="form-control mb-2"
                                                                                    v-model="medicineFormedit.category"
                                                                                    @change="checkForCustomCategory">
                                                                                    <option value="" disabled selected>
                                                                                        Select a category type</option>
                                                                                    <option value="custom">Add Custom
                                                                                        Category</option>
                                                                                    <option v-for="categories in categ"
                                                                                        :key="categories"
                                                                                        :value="categories">{{
                                                                                            categories }}
                                                                                    </option>
                                                                                </select>

                                                                            </div>
                                                                        </div>
                                                                        <div v-if="isAddingCustomCategory" class="mt-2">
                                                                            <input type="text" class="form-control"
                                                                                v-model="medicineFormedit.category"
                                                                                maxlength="25"
                                                                                placeholder="Enter new category" />
                                                                            <button class="btn btn-primary mt-2"
                                                                                @click="addCustomCategory">Add
                                                                                Category</button>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <div id="app">
                                                                                <select class="form-control mb-2"
                                                                                    v-model="medicineFormedit.medicinetype">
                                                                                    <option value="" disabled selected>
                                                                                        Select a medicine type</option>
                                                                                    <option
                                                                                        v-for="type in medicinetypes"
                                                                                        :key="type" :value="type">{{
                                                                                            type }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormedit.medstrength"
                                                                                maxlength="25"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormedit.medstrength }">
                                                                                Medicine Strength</label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormedit.usagedetails"
                                                                                @blur="firstnameTouched = true"
                                                                                maxlength="500"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormedit.usagedetails }">
                                                                                Medicine usuage</label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormedit.defaultdose"
                                                                                maxlength="25"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormedit.defaultdose }">
                                                                                default dose</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="text-center" style="margin-bottom:40px">
                                                                        <a class="btn btn-secondary btn-sm"
                                                                            @click="cancelUpdateMedicineForm"
                                                                            style="margin-right: 15px">Cancel</a>
                                                                        <a class="btn btn-primary btn-sm"
                                                                            @click="updateMedicineUltra"
                                                                            v-if="updatetrue">Update
                                                                            medicine</a>
                                                                        <a class="btn btn-primary btn-sm"
                                                                            @click="updateMedicineForm"
                                                                            v-if="updatetrueapi">Update
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <!-- update -->
                                                                <div v-if="isVisible">

                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormextra.medicinename"
                                                                                maxlength="100" />
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormextra.medicinename }"
                                                                            >Medicine
                                                                                Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormextra.brandname"
                                                                                :class="{ 'is-invalid': brandTouched }"
                                                                                maxlength="100"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormextra.brandname }">Brand
                                                                                Name</label>
                                                                        </div>
                                                                    </div>


                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <div id="app">
                                                                                <select class="form-control mb-2"
                                                                                    v-model="medicineFormextra.category"
                                                                                    @change="checkForaddCustomCategory">
                                                                                    <option value="" disabled selected>
                                                                                        Select a category type</option>
                                                                                    <option value="custom">Add Custom
                                                                                        Category</option>
                                                                                    <option v-for="categories in categ"
                                                                                        :key="categories"
                                                                                        :value="categories">{{
                                                                                            categories }}
                                                                                    </option>
                                                                                </select>

                                                                            </div>
                                                                        </div>
                                                                        <div v-if="addisAddingCustomCategory"
                                                                            class="mt-2">
                                                                            <input type="text" class="form-control"
                                                                                v-model="medicineFormextra.category"
                                                                                placeholder="Enter new category" />
                                                                            <button class="btn btn-primary mt-2"
                                                                                @click="addaddCustomCategory">Add
                                                                                Category</button>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <div id="app">
                                                                                <select class="form-control mb-2"
                                                                                    v-model="medicineFormextra.medicinetype">
                                                                                    <option value="" disabled selected>
                                                                                        Select a medicine type</option>
                                                                                    <option
                                                                                        v-for="type in medicinetypes"
                                                                                        :key="type" :value="type">{{
                                                                                            type }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    
                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormextra.medstrength"
                                                                                :class="{ 'is-invalid': strengthTouched }"
                                                                                maxlength="25"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormextra.medstrength }">
                                                                                Medicine Strength</label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormextra.usagedetails"
                                                                                 :class="{ 'is-invalid': usuageTouched }"
                                                                                maxlength="500"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormextra.usagedetails }">
                                                                                Medicine usuage</label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="text-align: center; margin-left: 30px; margin-right: 30px; margin-top:20px;">
                                                                        <div class="mb-3 form-focus">
                                                                            <b-form-input type="text"
                                                                                class="form-control floating "
                                                                                v-model="medicineFormextra.defaultdose"
                                                                                :class="{ 'is-invalid': doseTouched }"
                                                                                maxlength="25"/>
                                                                            <label class="focus-label"
                                                                            :class="{ 'has-input': medicineFormextra.defaultdose }">
                                                                                default dose</label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="text-center" style="margin-bottom:40px">
                                                                        <a class="btn btn-secondary btn-sm"
                                                                            @click="cancelAddMedicineForm"
                                                                            style="margin-right: 15px">Cancel</a>
                                                                        <a class="btn btn-primary btn-sm"
                                                                            @click="submitMedicineForm">Add
                                                                            medicine</a>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                            <template #item="{ element, index }">
                                                                <div class="medicine-itemx">
                                                                    <div class="medicine-details">
                                                                        <div class="edit-section">

                                                                            <button class="delete"
                                                                                @click="removeMedicine(element.medid)">
                                                                                ✕
                                                                            </button>
                                                                            <button class="edit" @click="
                                                                                editMedicine(element.medid)">
                                                                                <i class="fa fa-pencil"
                                                                                    aria-hidden="true"></i>
                                                                            </button>
                                                                            <p><strong>{{
                                                                                element.medicinename }} -
                                                                                </strong>{{
                                                                                    element.medicinetype }} ({{
                                                                                    element.medstrength }})</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </draggable>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </div> -->


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade custom-modal" id="appt_details">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Appointment Details</h5>
                    <b-button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></b-button>
                </div>
                <div class="modal-body">
                    <ul class="info-details">
                        <li>
                            <div class="details-header">
                                <div class="row">
                                    <div class="col-md-6">
                        <li>
                            <span class="title">Appointment ID:</span>
                            <span class="text">{{ selectedAppointment.appointmentid }}</span>
                        </li>
                        <li>
                            <span class="title">Appointment Date & Time:</span>
                            <span class="text">{{ formatDate(selectedAppointment.appointmentdate) }} {{
                                formatTime(selectedAppointment.appointmenttime) }}</span>
                        </li>
                </div>
                <div class="col-md-6">
                    <div class="text-end">
                        <b-button type="button"
                            :class="{ 'btn bg-success-light btn-sm': appointmentStatus === 'Upcoming', 'btn bg-danger-light btn-sm': appointmentStatus !== 'Upcoming' }"
                            id="topup_status">{{ appointmentStatus }}</b-button>
                    </div>
                </div>
            </div>
        </div>
        </li>
        <li>
            <span class="title">Status:</span>
            <span class="text">{{ appointmentStatus }}</span>
        </li>
        <li>
            <span class="title">Confirm Date:</span>
            <span class="text">{{ formatDate(selectedAppointment.createddate) }}</span>
        </li>
        </ul>
    </div>
    </div>
    </div>
    </div>
    <doctorfooter />
</template>

<script>
import axios from "axios";
import appointment from "@/assets/json/doctor/appointment.json";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import draggable from 'vuedraggable';

export default {
    components: {
        draggable
    },
    data() {
        return {
            isVisible: false,
            isVisibleforadd: false,
            isDisable: false,
            selectedTable: 'Medicines',
            patient: {
                contactNumber: '',
                email: '',
                name: '',
            },
            medicineForm: {
                MedicineName: '',
                BrandName: '',
                Category: 'Sedation',
                MedicineType: 'Tablets',
                MedStrength: '',
                UsageDetails: '',
                DefaultDose: '',
            },
            medicineFormextra: {
                medicinename: '',
                brandname: '',
                category: '',
                medicinetype: 'Tablets',
                medstrength: '',
                usagedetails: '',
                defaultdose: ''
            },
            medicineFormedit: {
                medicinename: '',
                brandname: '',
                category: '',
                medicinetype: '',
                medstrength: '',
                usagedetails: '',
                defaultdose: ''
            },
            tableSearch: ['Medicines', 'My medicines'],
            DoctorId: null,
            dateFormatx: 'yyyy-MM-dd',
            patientid: null,
            searchResults: [],
            schedules: [],
            showError: false,
            error: null,
            firstnameTouched: false,
            lastnameTouched: false,
            emailTouched: false,
            phoneTouched: false,
            searchPerformed: null,
            title: "Appointments History",
            text: "Home",
            text1: "Appointments History",
            appointment: appointment,
            upcomingAppointments: [],
            pastAppointmentfilter: [],
            pastxAppointmentfilter: [],
            dropdowndata: [],
            medicinetypes: ['Tablets', 'Injection', 'Syrup', 'Capsules',
                'Suppository', 'Ointment', 'Solution', 'Lotion', 'Cream', 'Powder',
                'Drops', 'Granules', 'Inhalation', 'Others'],
            categ: [],
            newAppointmentfilter: [],
            newAppointmentfilterx: [],
            tempnewAppointmentfilter: [],
            tempnewAppointmentfilters: [],
            tempnewAppointmentfilterapi: [],
            tempnewAppointmentfiltersapi: [],
            selectedAppointment: {},
            isActive: true,
            selectedClinic: '',
            appointmentdate: null,
            showSuccessAlert: false,
            showSuccessWarning: false,
            showSuccessDelete: false,
            showSuccessUpdate: false,
            showSuccessUpdatex: false,
            deleteMessage: "",
            successMessage: "",
            successMessageUpdate: "",
            successMessageUpdatex: "",
            successMessageWarning: "",
            editIndex: null,
            isVisibleforedit: false,
            updatetrue: false,
            updatetrueapi: false,
            allMedicines: [],
            mediformedicineform: '',
            mediformedicineformultra: '',
            medidfinal: '',
            medName: '',
            selectedCategoryx: '',
            selectedCategory: '',
            isAddingCustomCategory: false,
            addisAddingCustomCategory: false,
            newCategory: '',
            nameTouched:false,
            brandTouched:false,
            strengthTouched:false,
            doseTouched:false,
        };
    },
    computed: {
        appointmentStatus() {
            const currentDate = new Date();
            const appointmentDate = new Date(
                `${this.selectedAppointment.appointmentdate}T${this.selectedAppointment.appointmenttime}`
            );

            if (this.selectedAppointment.appointmentstatus === "C") {
                return "Cancelled";
            } else if (appointmentDate < currentDate) {
                return "Past";
            } else {
                return "Upcoming";
            }
        },
        uniqueCategories() {
            return [...new Set(this.dropdowndata)];
        },
    },

    async mounted() {
        await this.fetchDoctorDetails();
        await this.fetchMedicines();
    },
    async created() {
        await this.fetchMedicines();
        this.fetchDoctorMedicines();
        if (this.newAppointmentFilterx) {
        this.isDisable = this.newAppointmentFilterx.length !== 0;
    } else {
        this.isDisable = false;
    }
    },
    watch: {
        newAppointmentFilterx: {
            handler(newVal) {
                this.isDisable = newVal.length !== 0;
                console.log("newAppointmentFilterx updated:", this.newAppointmentFilterx);
            },
            deep: true 
        }
    },
    methods: {
        handleCategoryChange() {
            if (this.selectedTable !== 'Medicines') {

                this.fetchDoctorMedicines();
            }
            else {
                this.fetchMedicines();
            }
        },
        checkSelection(event) {

            if (this.selectedTable !== 'Medicines') {

                this.fetchDoctorMedicines();
            }
            else {
                this.fetchMedicines();
            }
        },

        handleMedicineNameChange() {
            this.fetchMedicines();
        },

        async fetchDoctorDetails() {
            const doctorId = sessionStorage.getItem("userdbid");
            try {
                let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
                if (!docDetails) {
                    const response = await axios.get(`/doctors/${doctorId}`);
                    docDetails = response.data;
                    sessionStorage.setItem(
                        `doctorDetails${this.doctorId}`,
                        JSON.stringify(docDetails)
                    );
                } else {
                    docDetails = JSON.parse(docDetails);
                }
                this.doctorDetails = docDetails;
                this.selectedCategory = docDetails.specialization;
            } catch (error) {
                console.error("Error fetching doctor details:", error);
            }
        },
        async fetchMedicines() {
            try {
                const response = await axios.get(`/doctors/medicines`);
                const allMedicines = response.data;

                this.dropdowndata = response.data.map(item => item.category);
                this.categ = [...new Set(response.data.map(item => item.category))];
                this.pastxAppointmentfilter = allMedicines.filter(item => {
                    return (
                        (this.medName === '' || item.medicinename.toLowerCase().includes(this.medName.toLowerCase()))
                    );
                });
            } catch (error) {
                console.error("Error fetching medicines:", error);
            }
        },
        async fetchDoctorMedicines() {
            try {
                const doctorId = sessionStorage.getItem("userdbid");
                this.DoctorId = doctorId;
                const response = await axios.get(`/doctors/${doctorId}/medicines`);
                const docMedicines = response.data;
                this.newAppointmentfilter = response.data;
            } catch (error) {
                console.error("Error fetching doctor medicines:", error);
            }
        },


        toggleVisibility() {
            this.isVisible = !this.isVisible;
        },

        checkMove(evt) {
            return evt.from === this.$refs.pastxAppointmentfilter;
        },
        onDrop(source, event) {
            if (event.oldIndex === undefined || event.newIndex === undefined) {
                return;
            }
            const draggedItemIndex = event.oldIndex;
            const movedAppointment = { ...this.pastxAppointmentfilter[draggedItemIndex - 1] };
            const medExiststhreePast = this.newAppointmentfilter.some(item =>
                item.medicinename === movedAppointment.medicinename && item.medicinetype === movedAppointment.medicinetype

            );

            const medExiststhreeNew = this.newAppointmentfilterx.some(item =>
                item.medicinename === movedAppointment.medicinename && item.medicinetype === movedAppointment.medicinetype
            );
            const medExiststhree = medExiststhreePast || medExiststhreeNew;

            if (medExiststhree) {
                setTimeout(() => {
                    this.showSuccessWarning = true;
                    this.successMessageWarning = "Duplicate medicines detected.";
                    setTimeout(() => {
                        this.showSuccessWarning = false;
                        this.successMessageWarning = "";
                    }, 10000);
                }, 0);
                console.log("med");
            }
            if (source === 'past') {
                this.newAppointmentfilterx.splice(event.newIndex, 0, movedAppointment);
                this.newAppointmentfilter.splice(event.newIndex, 0, movedAppointment);
                this.isDisable=true;
            } else if (source === 'new') {

                return;
            }
        },

        editMedicine(medid) {
            this.isVisibleforedit = true;
            this.medidfinal = medid;
            const medicineIndex = this.newAppointmentfilter.findIndex(medicine => medicine.medid === medid);
            const medicineIndexforultra = this.newAppointmentfilterx.findIndex(medicine => medicine.medid === medid);
            this.mediformedicineform = medicineIndex;
            this.mediformedicineformultra = medicineIndexforultra;
            if (typeof this.newAppointmentfilter[medicineIndex].doctorid === 'undefined') {
                this.updatetrue = true;
                this.updatetrueapi = false;

                const selectedMedicine = this.newAppointmentfilterx[this.mediformedicineformultra];
                this.tempnewAppointmentfilter = this.newAppointmentfilterx.filter((item, index) => {
                    return index !== this.mediformedicineformultra;
                });
                this.tempnewAppointmentfilters = this.newAppointmentfilter.filter((item, index) => {
                    return index !== this.mediformedicineform;
                });
                this.medicineFormedit = { ...selectedMedicine };
                console.log("this.medicineFormedit xxjx", this.medicineFormedit);
            }
            else {

                this.updatetrue = false;
                this.updatetrueapi = true;
                const selectedMedicine = this.newAppointmentfilter[this.mediformedicineform];


                this.tempnewAppointmentfilterapi = this.newAppointmentfilterx.filter((item, index) => {
                    return index !== this.mediformedicineform;
                });
                this.tempnewAppointmentfiltersapi = this.newAppointmentfilter.filter((item, index) => {
                    return index !== this.mediformedicineform;
                });
                this.medicineFormedit = { ...selectedMedicine };
                console.log("this.medicineFormedit", this.medicineFormedit);
                if (this.medicineFormedit.category && !this.categ.includes(this.medicineFormedit.category)) {
                    this.categ.push(this.medicineFormedit.category);
                    console.log("this.categ", this.categ);
                }
            }
        },
        checkForCustomCategory() {
            if (this.medicineFormedit.category === 'custom') {
                this.isAddingCustomCategory = true;
                this.medicineFormedit.category = '';
            } else {
                this.isAddingCustomCategory = false;
                this.medicineFormedit.category = this.medicineFormedit.category;
            }
        },
        addCustomCategory() {
            const trimmedCategory = this.medicineFormedit.category.trim();
            if (trimmedCategory && !this.categ.includes(trimmedCategory)) {
                this.categ.push(trimmedCategory);
                this.medicineFormedit.category = trimmedCategory;
                this.isAddingCustomCategory = false;

            }
        },
        checkForaddCustomCategory() {
            if (this.medicineFormextra.category === 'custom') {
                this.addisAddingCustomCategory = true;
                this.medicineFormextra.category = '';
            } else {
                this.addisAddingCustomCategory = false;
                this.medicineFormextra.category = this.medicineFormextra.category;
            }
        },
        addaddCustomCategory() {
            const trimmedCategory = this.medicineFormextra.category.trim();
            if (trimmedCategory && !this.categ.includes(trimmedCategory)) {
                this.categ.push(trimmedCategory);
                this.medicineFormextra.category = trimmedCategory;
                this.addisAddingCustomCategory = false;

            }
        },

        updateMedicineUltra() {
            const medicineIndextwo = this.mediformedicineformultra;
            const medicineIndexone = this.mediformedicineform;

            const medExiststwo =
                this.tempnewAppointmentfilters.some(item =>
                    item.medicinename.toLowerCase() === this.medicineFormedit.medicinename.toLowerCase() &&
                    item.medicinetype.toLowerCase() === this.medicineFormedit.medicinetype.toLowerCase()
                )
                ||
                this.tempnewAppointmentfilter.some(item =>
                    item.medicinename.toLowerCase() === this.medicineFormedit.medicinename.toLowerCase() &&
                    item.medicinetype.toLowerCase() === this.medicineFormedit.medicinetype.toLowerCase()
                );
            if (medExiststwo) {
                setTimeout(() => {
                    this.showSuccessWarning = true;
                    this.successMessageWarning = "Duplicate medicines detected.";
                    setTimeout(() => {
                        this.showSuccessWarning = false;
                        this.successMessageWarning = "";
                    }, 10000);
                }, 0);
            }
            this.newAppointmentfilterx[medicineIndextwo] = { ...this.medicineFormedit };
            this.newAppointmentfilter[medicineIndexone] = { ...this.medicineFormedit };
            this.isVisibleforedit = false;
            this.tempnewAppointmentfilter = [];
            this.tempnewAppointmentfilters = [];
        },
        async updateMedicineForm() {
            try {
                const medExiststwo =
                    this.tempnewAppointmentfiltersapi.some(item =>
                        item.medicinename.toLowerCase() === this.medicineFormedit.medicinename.toLowerCase() &&
                        item.medicinetype.toLowerCase() === this.medicineFormedit.medicinetype.toLowerCase()

                    )
                    ||
                    this.tempnewAppointmentfilterapi.some(item =>
                        item.medicinename.toLowerCase() === this.medicineFormedit.medicinename.toLowerCase() &&
                        item.medicinetype.toLowerCase() === this.medicineFormedit.medicinetype.toLowerCase()
                    );
                if (medExiststwo) {
                    setTimeout(() => {
                        this.showSuccessWarning = true;
                        this.successMessageWarning = "Duplicate medicines detected.";
                        setTimeout(() => {
                            this.showSuccessWarning = false;
                            this.successMessageWarning = "";
                        }, 10000);
                    }, 0);
                }
                const doctorId = sessionStorage.getItem("userdbid");
                const response = await axios.put(`/doctors/${doctorId}/medicines/${this.medidfinal}`, this.medicineFormedit);
                setTimeout(() => {
                    this.showSuccessUpdate = true;
                    this.successMessageUpdate = "Medicines updated successfully!";
                    setTimeout(() => {
                        this.showSuccessUpdate = false;
                        this.successMessageUpdate = "";
                    }, 3000);
                }, 0);
                this.isVisibleforedit = false;
                this.tempnewAppointmentfilterapi = [];
                this.tempnewAppointmentfiltersapi = [];
            }
            catch (error) {
                console.error('Error updating doctor medicines:', error);
            }
        },

        async removeMedicine(medid) {
            try {
                const doctorId = sessionStorage.getItem("userdbid");
                const response = await axios.delete(`/doctors/${doctorId}/medicines/${medid}`);
                if (response.status === 200) {
                    const medicineIndex = this.newAppointmentfilter.findIndex(medicine => medicine.medid === medid);
                    if (medicineIndex !== -1) {
                        this.newAppointmentfilter.splice(medicineIndex, 1);
                    }
                    setTimeout(() => {
                        this.showSuccessDelete = true;
                        this.deleteMessage = "Medicine deleted successfully.";
                        setTimeout(() => {
                            this.showSuccessDelete = false;
                            this.deleteMessage = "";
                        }, 3000);
                    }, 0);
                } else {
                    console.error(`Failed to delete medicine: ${response.statusText}`);
                }
            } catch (error) {
                console.error("Error deleting medicine:", error);
                const medicineIndex = this.newAppointmentfilterx.findIndex(medicine => medicine.medid === medid);
                const medicineIndexone = this.newAppointmentfilter.findIndex(medicine => medicine.medid === medid);
                const adjustedIndex = medicineIndex + 1;
                if (medicineIndex !== -1) {
                    this.newAppointmentfilter.splice(medicineIndexone, 1);
                    this.newAppointmentfilterx.splice(medicineIndex, 1);
                }
            }
        },
        removeAll() {
            this.newAppointmentfilter = [];
        },
        resetForm() {
            this.patient.contactNumber = '';
            this.patient.email = '';
            this.patient.name = '';
            this.appointmentdate = null;
            this.phoneTouched = false;
            this.emailTouched = false;
            this.firstnameTouched = false;
            if (this.schedules.length > 0) {
                this.selectedClinic = this.schedules[0].clinicid;
            }
            this.searchPerformed = false;
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
            });
        },
        cancelAddMedicineForm() {
            this.medicineFormextra = {
                medicinename: '',
                brandname: '',
                category: 'Sedation',
                medicinetype: '',
                medstrength: '',
                usagedetails: '',
                defaultdose: ''
            };
            this.isVisible = false;
        },

        cancelUpdateMedicineForm()
        {
            this.medicineFormedit = {
                medicinename: '',
                brandname: '',
                category: '',
                medicinetype: '',
                medstrength: '',
                usagedetails: '',
                defaultdose: ''
            };
            this.isVisibleforedit = false;
        },

        submitMedicineForm() {
            const medExiststwo = this.newAppointmentfilter.some(item =>
                item.medicinename.toLowerCase() === this.medicineFormextra.medicinename.toLowerCase()
            ) || this.newAppointmentfilterx.some(item =>
                item.medicinename.toLowerCase() === this.medicineFormextra.medicinename.toLowerCase()
            );
            if (medExiststwo) {
                setTimeout(() => {
                    this.showSuccessWarning = true;
                    this.successMessageWarning = "Duplicate medicines detected.";
                    setTimeout(() => {
                        this.showSuccessWarning = false;
                        this.successMessageWarning = "";
                    }, 10000);
                }, 0);
            }
            this.newAppointmentfilterx.push({ ...this.medicineFormextra });
            this.newAppointmentfilter.push({ ...this.medicineFormextra });
            this.medicineFormextra = {
                medicinename: '',
                brandname: '',
                category: '',
                medicinetype: '',
                medstrength: '',
                usagedetails: '',
                defaultdose: ''
            };
            this.isVisible = false;
            this.isDisable = true;
        },
        async confirmAll() {
            try {
                this.isVisibleforadd = false;
                const doctorId = sessionStorage.getItem("userdbid");
                const filteredData = this.newAppointmentfilterx.filter(item => item !== null);

                const response = await axios.post(`/doctors/${doctorId}/medicines`, filteredData);
                setTimeout(() => {
                    this.showSuccessAlert = true;
                    this.successMessage = "Medicines added successfully.";
                    setTimeout(() => {
                        this.showSuccessAlert = false;
                        this.successMessage = "";
                    }, 3000);
                }, 0);
                this.isDisable=false;
                this.newAppointmentfilterx = [];
                this.fetchDoctorMedicines();
            } catch (error) {
                console.error('Error submitting doctor medicines:', error);
            }
        },
        formatDatex(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formatTime(time) {
            return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
    },
    name: "appointments"
};
</script>
<style scoped>
.btn.disabled,
.btn:disabled {
    pointer-events: none;
    opacity: 0.3;
}

.modal-content .btn {
    cursor: auto;
}

.medicine-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-containerc {
    display: flex;
    justify-content: center;
    width: 100%;
}


.hover-text {
    cursor: pointer;
    color: grey;
}

.hover-textx {
    cursor: pointer;
    color: lightseagreen;
    font-size: 12px;
}


.hover-text:hover {
    color: lightcoral;
}

.hover-textx:hover {
    /* color: #0E82FD; */
    color: green;
}

.responsive-tabx {
    display: none;
}

@media (max-width: 992px) {
    .responsive-tabx {
        margin-top: 60px;
        display: block;
        margin-bottom: 20px;
    }
}

@media (max-width: 992px) {
    .drapsize {
        font-size: large;
    }
}

.responsive-taby {
    display: block;
}

@media (max-width: 992px) {
    .responsive-taby {
        display: none;
    }
}

.edit-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.medicine-label {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    margin: 3px;
    position: relative;
}

.rounded-button {
    background-color: #ff6b6b;
    color: white;
    border-radius: 50%;
    border: none;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.rounded-buttonx {
    background-color: #008CBA;
    color: white;
    border-radius: 50%;
    border: none;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.custom-width {
    width: 600px;
}

@media (max-width: 767px) {
    .custom-width {
        width: 150px;
        margin-right: 20px;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .custom-width {
        width: 400px;
        margin-right: 20px;
    }
}

.rounded-button:hover {
    background-color: white;
    color: black;
}

.rounded-buttonx:hover {
    background-color: white;
    color: black;
}


.medicine-info {
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
}

.increased-height {
    height: 50px;
}

.custom-font-size {
    font-size: 12px;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
    margin: 0;
    padding: 0;
}

@media (max-width: 767px) {
    .col-md-16.col-lg-6.col-xl-6.ml-auto {
        margin-bottom: 30px;
    }
}

.container-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
}

@media (max-width: 767px) {
    .container-flex {
        margin-bottom: 5px;
    }
}

.input-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.input-wrapper {
    position: relative;
    width: 100%;
    max-width: 300px;
}

.input-wrapper input {
    padding-left: 35px;
}

.input-wrapper i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #999;
}

.select-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    padding: 15px;
    max-height: 95vh;
    min-height: 95vh;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.alerts {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* Adjust the gap as needed */
}

.modal-body {
    display: flex;
    gap: 20px;
    flex-grow: 1;
}

.medicine-list-container {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.medicine-list,
.prescription-list {
    flex: 1;
    max-height: 69vh;
    min-height: 69vh;
    overflow-y: auto;
    padding: 5px;
    background-color: #f4f4f4;
    border-radius: 8px;
}

.medicine-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
}

.medicine-details p {
    margin: 0;
}

.medicine-itemx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    min-height: 10px;
    display: flex;
    align-items: center;
}



.medicine-item:hover {
    background-color: #e9e9e9;
}

.prescription-list-container {
    display: flex;
    color: #000;
    flex-direction: column;
    width: 50%;
}

.search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.search-bar input {
    flex: 1;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

@media screen and (max-width: 600px) {
    .search-bar input {
        width: 25%;
    }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
    .search-bar input {
        width: 75%;
    }
}

.text-endx .btn {
    width: 50px;
    height: 25px;
    font-size: 12px
}

@media screen and (max-width: 600px) {
    .text-endx .btn {
        width: 30px;
        height: 20px;
        font-size: 7px
    }
}

@media screen and (max-width: 600px) {
    .hover-textx {
        cursor: pointer;
        color: lightseagreen;
        font-size: 10px;
    }
}

.search-bar i {
    margin-left: 5px;
    color: #888;
}

.delete {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    background-color: #ff6b6b;
    color: white;
    border: none;
    padding: 5px;
    font-size: 12px;
    width: 30px;
}

.edit {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    cursor: pointer;
    /* background-color: rgba(0, 138, 184, 0.142); */
    color: black;
    background-color: white;
    border: none;
    padding: 5px;
    font-size: 12px;
    width: 30px;
}
</style>
