<template>
  <div class="main-wrapper">
    <layoutheader v-if="!isModalOpen"></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <div class="page-wrapper">
      <div class="content">
        <div class="container">
          <div class="row">
            <!-- Profile Sidebar -->
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div class="stickysidebar">
                <profilesidebar></profilesidebar>
              </div>
            </div>
            <!-- / Profile Sidebar -->
            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="card">
                <div class="card-body pt-0">
                  <!-- Tab Menu -->
                  <nav class="user-tabs mb-4">
                    <div class="col-sm-6" style="padding-top:10px">
                      <h3 class="card-title">Appointments</h3>
                    </div>
                    <!-- Appointment Tab -->
                    <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#pat_appointments"
                          data-bs-toggle="tab"
                        >Upcoming</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#pat_prescriptions" data-bs-toggle="tab">Past</a>
                      </li>
                    </ul>
                    <!-- /Appointment Tab -->
                  </nav>
                  <!-- /Tab Menu -->

                  <!-- Tab Content -->
                  <div class="tab-content pt-0">
                    <!-- Appointment Tab -->
                    <patientappointment
                      :futureAppointments="futureAppointments"
                      :fetchAppointments="fetchAppointments"
                      @add-medical-records="handleAddMedicalRecords"
                      @view-medical-records="handleViewMedicalRecords"
                      
                    ></patientappointment>
                    <!-- /Appointment Tab -->

                    <!-- Past Appointment Tab -->
                    <prescription v-bind:isModalOpen="isModalOpen"
      @openModal="openModal"
      @closeModal="closeModal" :pastAppointments="pastAppointments" @add-medical-records="handleAddMedicalRecords" @view-medical-records="handleViewMedicalRecords"></prescription>
                    <!-- /Past Appointment Tab -->
                     
                  </div>
                  <!-- Tab Content -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <indexfooter></indexfooter>
    </div>
  </div>
  <!-- Add medical Records -->
  <patient-medical-record :appointmentId="selectedAppointmentId" :patientId="selectedPatientId" :doctorId="selectedDoctorId"  @records-added="onRecordsAdded" ></patient-medical-record>
  <!-- /Add medical Records -->

  <!-- view medical records -->
  <view-medical-records :patientId="recordsPatientId" :appointmentId="recordsAppointmentId" ref="viewMedicalRecords"> </view-medical-records>
  <!-- view medical records -->
  <graphone></graphone>
  <graphtwo></graphtwo>
  <graphthree></graphthree>
  <graphfour></graphfour>
</template>

<script>
import axios from "axios";
import graphstatus from "@/assets/json/patientdashboard/graphstatus.json";
import patient from "@/assets/json/patientdashboard/patient.json";
export default {
  data() {
    return {
      title: "Dashboard",
      text: "Home",
      text1: "Dashboard",
      graphstatus: graphstatus,
      patient: patient,
      idpid: "",
      patientid: "",
      futureAppointments: [],
      pastAppointments: [],
      selectedAppointmentId: null,
      selectedDoctorId: null,
      selectedPatientId: null,
      recordsPatientId:null,
      isModalOpen: false,
      recordsAppointmentId:null

    };
  },
  methods: {
  openModal() {
    this.isModalOpen = true;
  },
  closeModal() {
    this.isModalOpen = false;
  },
    handleAddMedicalRecords({appointmentId, patientId, doctorId}) {
      this.selectedAppointmentId = appointmentId;
      this.selectedPatientId = patientId;
      this.selectedDoctorId = doctorId;
    },
    handleViewMedicalRecords({ patientId, appointmentId }) {
      this.recordsPatientId = patientId;
      this.recordsAppointmentId = appointmentId;
      console.log("this is recordid", this.recordsPatientId, this.recordsAppointmentId)
    },
    onRecordsAdded() {
      this.recordsPatientId = this.selectedPatientId;
      this.recordsAppointmentId = this.selectedAppointmentId;
      // Fetch the updated list of medical records
      this.$refs.viewMedicalRecords.fetchMedicalRecords();
    },
    async fetchAppointments() {
      this.patientid = sessionStorage.getItem('userdbid');
      try {
        const response = await axios.get(
          `/patients/${this.patientid}/appointments`
        );

        console.log("Response from get_patient_appointments API:", response);

        if (response.data && response.data.future && response.data.past) {
          this.futureAppointments = response.data.future;
          this.pastAppointments = response.data.past; 
         
          const promises = this.pastAppointments.map(async (appointment, index) => {
        const prescripid = await this.checkPrescription(appointment);
        this.pastAppointments[index].prescripid = prescripid;
      });

      await Promise.all(promises);
      console.log("appts: ,",this.pastAppointments);
    
        } else {
          console.error("Appointments not found in the response.");
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    },
    async checkPrescription(appointment) {
  try {
    // API call to check if the prescription exists for the given appointment
    const response = await axios.get(`/patients/1/appointments/${appointment.appointment_id}/prescriptions`);
    console.log(`Prescription check for appointment ${appointment.appointment_id}:`, response.data);
     return response.data && response.data[0] && response.data[0].prescripid ? response.data[0].prescripid : null;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      console.error(`Error checking prescription for appointment ${appointment.appointment_id}:`, error);
      return null;
      }
    }
    }
  },
  
  mounted() {
    this.fetchAppointments();
  }
};
</script>
